import { gql } from '@apollo/client';
import LOCAL_STORAGE_KEYS from 'constants/local-storage';
import { CurrentUserData } from 'graphql.g';
import useCachedState from 'utils/use-cached-state';
import useData from 'utils/use-data';

export const USER_QUERY = gql`
    query CurrentUser {
        user {
            id
            isStaff
            enabledFeatures
            hasAcceptedTerms
            email
        }
    }
`;

/**
 * Regularly-needed info about the current user.
 * Supplied by a <UseProvider> somewhere higher up in the component tree.
 */
export function useUser() {
    const data = useData<CurrentUserData>(USER_QUERY);
    return data?.user ?? null;
}

export function useFeaturePreview() {
    return useCachedState(LOCAL_STORAGE_KEYS.featurePreview, false);
}
