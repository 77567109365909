import React from 'react';
import "/opt/build/repo/src/common/header.tsx?resplendence=true";
import logoIcon from 'ride-ui-kit/dist/assets/logo_icon.svg';

import { Link } from 'react-router-dom';

export function Logo() {
    return <img alt="Ride Report" src={logoIcon} className="logo" />;
}

/*
@import 'style.scss';
*/;

const LINK = "rx-common-header-1"/*
    height: 100%;
    width: auto;
*/;

interface Props {
    children?: any;
    link?: string;
}

/**
 * A simplified version of AppHeader that doesn't rely on user data,
 * or anything that would require an authenticated user to view.
 * Can be used on routes where the user may not be logged in.
 */
function Header({ children, link }: Props) {
    return (
        <header>
            <Link className={LINK} to={link ?? '/'}>
                <Logo />
            </Link>
            {children}
        </header>
    );
}

export default Header;
