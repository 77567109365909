import React from 'react';
import "/opt/build/repo/src/root/terms-page.tsx?resplendence=true";
import { NavLink } from 'react-router-dom';
import { PageContent, PageColumns } from 'common/layout';
import { Icon } from 'ride-ui-kit';
import Header from 'common/header';

/*
@import 'style.scss';
*/;

const LINK = "rx-root-terms-page-1"/*
    @include navigation-link;
    transition: all 150ms;
    border-radius: 4rem;
    height: 36rem;
    padding: 0 6rem;
    display: grid;
    grid: 1fr / auto auto;
    grid-gap: 8rem;
    align-items: center;
    justify-content: start;
    svg {
        color: $gray-60;
    }
    &.active svg {
        color: $white;
    }
*/;

const ICON = "rx-root-terms-page-2"/*
    font-size: 24rem;
*/;

const NAV = "rx-root-terms-page-3"/*
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    grid-gap: 12rem;
    background-color: $white;
    height: max-content;
    padding: 24rem;
    border-radius: 4rem;

    @include mobile {
        grid-row: 1;
    }
*/;

const ARTICLE = "rx-root-terms-page-4"/*
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: $white;
    border-radius: 4rem;
*/;

type Props = {
    children: any;
};

/**
 * The layout shared between the Eula and Privacy pages
 */
function TermsPage({ children }: Props) {
    return (
        <>
            <Header />
            <PageContent>
                <PageColumns>
                    <article className={ARTICLE}>{children}</article>
                    <nav className={NAV}>
                        <NavLink to="/terms/eula" className={LINK}>
                            <Icon icon="Document-Agreement" className={ICON} />
                            <span>EULA</span>
                        </NavLink>
                        <NavLink to="/terms/privacy" className={LINK}>
                            <Icon icon="Document-Privacy" className={ICON} />
                            <span>Privacy</span>
                        </NavLink>
                    </nav>
                </PageColumns>
            </PageContent>
        </>
    );
}

export default TermsPage;
