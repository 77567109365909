import React from 'react';
import "/opt/build/repo/src/root/page-app-root-router.tsx?resplendence=true";

import { useUser } from 'common/user';
import ReviewTermsPage from './page-review-terms';
import AppHeader from './app-header';
import { Route, Switch } from 'react-router';

/*
@import 'style.scss';
*/;

/**
 * Establish what region (or "mapview") we are going to be looking at.
 */
function RootRouter() {
    const user = useUser();

    if (user && !user.hasAcceptedTerms) {
        return <ReviewTermsPage />;
    }
    return (
        <>
            <AppHeader />
            <Switch>
                <Route path="/:jurisdiction" render={() => <div>hi</div>} />
                <Route path="/" render={() => <div>wfewefwe</div>} />
            </Switch>
        </>
    );
}

export default RootRouter;
