import React, { useState, useEffect } from 'react';
import { IntlProvider as BaseIntlProvider, CustomFormats } from 'react-intl';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/dist/locale-data/en';
import '@formatjs/intl-pluralrules/dist/locale-data/fr';
import '@formatjs/intl-pluralrules/dist/locale-data/de';
import '@formatjs/intl-pluralrules/dist/locale-data/es';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/dist/locale-data/en';
import '@formatjs/intl-relativetimeformat/dist/locale-data/fr';
import '@formatjs/intl-relativetimeformat/dist/locale-data/de';
import '@formatjs/intl-relativetimeformat/dist/locale-data/es';

import LoadingSite from 'root/loading-site';

type Catalog = {
    [key: string]: string;
};

type Catalogs = {
    [locale: string]: Catalog;
};

const useIntlCatalogs = (locale: string) => {
    const [catalogs, setCatalogs] = useState<Catalogs>({});
    useEffect(() => {
        if (locale && !catalogs[locale]) {
            let cancelled = false;
            import(
                /* webpackMode: "lazy", webpackChunkName: "i18n-[index]" */
                `../translations/${locale}.json`
            ).then(m => {
                const catalog = m.default;
                if (!cancelled) {
                    setCatalogs({
                        ...catalogs,
                        [locale]: catalog
                    });
                }
            });
            return () => {
                cancelled = true;
            };
        }
    }, [catalogs, locale]);
    if (!locale || !catalogs[locale]) return null;
    return catalogs;
};

interface IntlProviderProps {
    children: React.ReactNode;
    locale: string;
}

export const getIntlFormats = (timeZone?: string): CustomFormats => ({
    date: {
        month: {
            month: 'long',
            timeZone
        },
        dayofmonth: {
            day: 'numeric',
            month: 'long',
            timeZone
        },
        dayandmonth: {
            day: 'numeric',
            month: 'numeric',
            timeZone
        },
        dayandyear: {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            timeZone
        },
        localdayofmonth: {
            day: 'numeric',
            month: 'long'
        },
        localdayandyear: {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        },
        chartday: {
            day: 'numeric',
            month: 'short'
        },
        reportday: {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        },
        reportmonth: {
            month: 'long',
            year: 'numeric'
        },
        localmonth: {
            month: 'long',
            year: 'numeric'
        },
        reportweek: {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        },
        dayOfWeekReportDay: {
            weekday: 'short',
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        },
        dayOfWeekReportWeek: {
            weekday: 'short',
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        }
    },
    time: {
        short: {
            hour: 'numeric',
            minute: 'numeric',
            timeZone
        },
        short24hour: {
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
            timeZone
        },
        hour: {
            hour: 'numeric'
        }
    }
});

export function IntlProvider({ children, locale }: IntlProviderProps) {
    const catalogs = useIntlCatalogs(locale);
    return catalogs ? (
        <BaseIntlProvider
            key={locale}
            locale={locale}
            defaultLocale="en"
            messages={catalogs && catalogs[locale]}
            formats={getIntlFormats()}
        >
            {children}
        </BaseIntlProvider>
    ) : (
        <LoadingSite />
    );
}

export { useIntl } from 'react-intl';
