import React, { useState } from 'react';
import "/opt/build/repo/src/root/app-header.tsx?resplendence=true";
import { Icon, IconName } from 'ride-ui-kit';
import cx from 'classnames';
import config from 'config';
import { FormattedMessage } from 'react-intl';
import { useAuthInfo } from 'common/authentication';
import { useFeaturePreview, useUser } from 'common/user';
import Header from 'common/header';

/*
@import 'style.scss';
*/;

const DROPDOWN_WRAPPER = "rx-root-app-header-1"/*
    position: relative;
*/;

const DROPDOWN_BUTTON = "rx-root-app-header-2"/*
    color: $white;
    display: flex;
    align-items: center;
    @include text-body;
    font-size: 16px;
    &:hover {
        svg {
            color: $white;
        }
    }
    @media print {
        color: black;
    }
*/;

const DROPDOWN_ICON = "rx-root-app-header-3"/*
    font-size: 20px;
    color: white;
    margin-left: 1px;
    transition-property: color;
    transition-duration: 150ms;
    @media print {
        display: none;
    }
*/;

const DROPDOWN = "rx-root-app-header-4"/*
    background-color: $white;
    border-radius: 6px;
    @include shadow-8;
    position: absolute;
    z-index: 100;
    bottom: 0;
    left: calc(100% + 8px);
    padding: 12px 0px;
    width: 240px;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 150ms;
    box-sizing: border-box;
    pointer-events: none;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    &.show {
        pointer-events: auto;
        opacity:1;
    }
    @include mobile-tiny {
        left: 20px;
    }
*/;

const DROPDOWN_OUTER = "rx-root-app-header-5"/*
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 99;
*/;

type DropdownProps = {
    label?: string;
    icon?: IconName;
    children: any;
};

function Dropdown({ label, icon, children }: DropdownProps) {
    const [isDropped, setIsDropped] = useState(false);

    const toggleMenu = () => {
        setIsDropped(!isDropped);
    };

    return (
        <div className={DROPDOWN_WRAPPER}>
            {isDropped ? (
                <div
                    className={DROPDOWN_OUTER}
                    onClick={() => {
                        setIsDropped(false);
                    }}
                />
            ) : null}
            <button
                type="button"
                className={DROPDOWN_BUTTON}
                onClick={toggleMenu}
            >
                {icon && <Icon icon={icon} className={DROPDOWN_ICON} />}
                {label}
                {!icon && (
                    <Icon
                        icon={isDropped ? 'Up' : 'Down'}
                        className={DROPDOWN_ICON}
                    />
                )}
            </button>
            <div className={cx(DROPDOWN, { show: isDropped })}>{children}</div>
        </div>
    );
}

const MOBILE_MENU_BUTTON = "rx-root-app-header-6"/*
    @include button;

    height: 36px;
    font-size: 16px;
    @include not-mobile-tiny {
        display: none;
    }
    @media print {
        display: none;
    }
    background-color: transparent;
    border-color: transparent;
    color: $white;
    font-size: 32px;
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    &:hover {
        background-color: $gray-60;
    }
*/;

const USER_BUTTON = "rx-root-app-header-7"/*
    margin: 0 12px;
    padding: 4px 12px;
    flex: 0 0 auto;
    color: $gray-90;
    @include text-body-link;
    font-size: 16px;
    &:hover {
        background: $gray-30;
    }
    border-radius: 6px;
*/;

const FEATURE_PREVIEW = "rx-root-app-header-8"/*
    position: absolute;
    top: 0;
    height: 100%;
    border-left: 4rem solid $green-50;
    left: 100%;
    z-index: 13;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    span {
        transform-origin: center left;
        transform: rotate(-90deg);
        background-color: $green-50;
        color: $white;
        @include text-label-bold;
        font-size: 12.8rem;
        padding: 0 8rem 2rem 8rem;
        border-radius: 0 0 3rem 3rem;
        margin-top: -3rem;
        white-space: nowrap;
        position: relative;
    }
*/;

const HEADER_CONTENT = "rx-root-app-header-9"/*
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
*/;

type Props = {
    toggleMobileNavMenu?: () => void;
};

/**
 * The top-level header component for the app.
 */
function AppHeader({ toggleMobileNavMenu }: Props) {
    const user = useUser();
    const authInfo = useAuthInfo();
    const [featurePreview, setFeaturePreview] = useFeaturePreview();

    const signout = () => {
        fetch(`${config.authHost}/logout`, {
            method: 'POST',
            headers: { Authorization: 'Bearer ' + authInfo.token }
        }).then(result => {
            if (result.status >= 300) {
                throw Error(`Logout returned non-200 code ${result.status}`);
            }
            authInfo.logout();
        });
    };

    return (
        <Header link="/">
            <div className={HEADER_CONTENT}>
                <Dropdown icon="User">
                    <a href="/terms" target="_blank" className={USER_BUTTON}>
                        <FormattedMessage
                            key="terms-link"
                            defaultMessage="Terms"
                        />
                    </a>
                    {user?.isStaff && (
                        <>
                            <button
                                type="button"
                                className={USER_BUTTON}
                                onClick={() =>
                                    setFeaturePreview(!featurePreview)
                                }
                            >
                                {featurePreview ? (
                                    <FormattedMessage
                                        key="hide-demo-features-button"
                                        defaultMessage="Hide demo features"
                                    />
                                ) : (
                                    <FormattedMessage
                                        key="show-demo-features-button"
                                        defaultMessage="Show demo features"
                                    />
                                )}
                            </button>
                        </>
                    )}
                    <button
                        type="button"
                        className={USER_BUTTON}
                        onClick={signout}
                    >
                        <FormattedMessage
                            key="signout-button"
                            defaultMessage="Sign Out"
                        />
                    </button>
                </Dropdown>
                {toggleMobileNavMenu ? (
                    <button
                        type="button"
                        className={MOBILE_MENU_BUTTON}
                        onClick={toggleMobileNavMenu}
                    >
                        <Icon icon="Hamburger" />
                    </button>
                ) : null}
                {/* if the user has demo features, show a banner. If not, and they
            are on an Atlas page, temporarily show an Early Access banner.
            see: https://github.com/RideReport/tickets/issues/4718 */}
                {featurePreview && (
                    <div className={FEATURE_PREVIEW}>
                        <FormattedMessage
                            tagName="span"
                            key="feature-preview"
                            defaultMessage="Feature Preview"
                        />
                    </div>
                )}
            </div>
        </Header>
    );
}

export default AppHeader;
