import React from 'react';
import RootRouter from './page-app-root-router';
import Apollo from './apollo';
import AuthInfoProvider from 'common/authentication';
import ErrorBoundary from 'common/error-boundary';

/**
 * All of the dashboard functionality.
 */
function App() {
    return (
        <AuthInfoProvider>
            <Apollo>
                <ErrorBoundary size="large">
                    <RootRouter />
                </ErrorBoundary>
            </Apollo>
        </AuthInfoProvider>
    );
}

export default App;
