import { useEffect, useState } from 'react';
import clone from 'lodash/clone';

import { initFullstory } from 'vendor/fullstory';
import { initSegment } from 'vendor/segment';
import { useLocation } from 'react-router';
import TOP_LEVEL_PATHS from 'root/top-level-paths';
import { PolicyType } from 'graphql.g';

const IS_PRODUCTION = process.env.NODE_ENV === 'production';

/** Can we track this user or not?
 * Defaults to false, and is set via the identifyAnalyticsUser  */
let canUseAnalytics = false;

function deepEqual(a, b) {
    return JSON.stringify(a) === JSON.stringify(b);
}

type PageViewData = Record<string, string | null>;

export const usePageViews = () => {
    const location = useLocation();
    const [lastPageView, setLastPageView] = useState<PageViewData>();

    useEffect(() => {
        // we wrap this in a setTimeout to implement our own 'debounce'
        // function so that we don't make too many calls. This prevents situations
        // where our code does quick redirects or sets default search params
        // from being logged as separate page views.
        const timer = setTimeout(() => {
            const pathParts = location.pathname
                .split('/')
                .filter(path => path !== '');

            const pageData: PageViewData = {
                mapview: null,
                page: null,
                search: location.search,
                restOfPath: null
            };

            if (Object.values(TOP_LEVEL_PATHS).includes(pathParts[0])) {
                // if we have a top level path instead of a mapview, use the first
                // part as the `page`
                // ex: /settings
                pageData.page = pathParts[0];
                pageData.restOfPath = pathParts.slice(1).join('/');
            } else {
                // if we don't know the top level path, we assume it's a mapview
                // in this case we set the mapview to the first part of the path
                // and use the second part as the `page`
                // ex: /pdx/policy
                pageData.mapview = pathParts[0];
                pageData.page = pathParts[1];
                pageData.restOfPath = pathParts.slice(2).join('/');
            }

            if (!deepEqual(lastPageView, pageData)) {
                setLastPageView(pageData);
                recordAnalytics('pageview', pageData);
            }
        }, 200);

        return () => clearTimeout(timer);
    }, [location.pathname, location.search, lastPageView]);
};

export type AnalyticsUserInfo = Record<
    string,
    string | boolean | number | null | undefined
>;

/**
 * Sends an analytics event to Segment
 */
export const recordAnalytics = (
    /** The event string to track */ event: string,
    /** Optional properties associated with the event */ properties?: object
) => {
    if (!canUseAnalytics || !IS_PRODUCTION) {
        return;
    }

    if (window.analytics) {
        window.analytics.track(event, clone(properties));
    }
};

/**
 * Sends information to FullStory and segment to uniquely identify a user so the user can be tracked across sessions.
 */
export const identifyAnalyticsUser = (
    /** The unique id to be associated with all analytics sessions for a user.
     * This value is the id of our user in the authservice database.
     */
    userId: string,
    /** has the user accepted our terms? */
    hasAcceptedTerms: boolean,
    /** Custom properties associated with a user so we can filter analytics event based on user properties.
     * These include email, user type (agency or operator), the operator if a user is associated with one,
     * and whether the user is a staff user.
     */
    customUserProps: AnalyticsUserInfo
) => {
    // only identify the user if they've accepted our terms
    if (hasAcceptedTerms === false) {
        return;
    }

    // if switching to allow analytics, load scripts
    if (canUseAnalytics === false && hasAcceptedTerms === true) {
        canUseAnalytics = true;
        initSegment();
        initFullstory();
    }

    if (window.analytics) {
        window.analytics.identify(userId, customUserProps);
    }

    if (window.fullstory) {
        window.fullstory.identify(userId, customUserProps);
    }
};

type EventProperties = { name: string | undefined };
interface AreaEvent extends EventProperties {
    event_name: 'area event';
    action: 'created' | 'deleted' | 'archived' | 'restored';
}

interface PolicyEvent extends EventProperties {
    event_name: 'policy event';
    action: 'created' | 'deleted';
    policy_type: PolicyType | undefined;
    rate_amount: number | undefined;
}

interface DataExportEvent extends EventProperties {
    event_name: 'data export event';
    action: 'printed' | 'exported' | 'failed';
    type?: string;
}

interface AdminEvent extends EventProperties {
    event_name: 'admin event';
    action: 'user added' | 'user deleted';
}

interface CustomDatasetEvent extends EventProperties {
    event_name: 'custom dataset event';
    action: 'created' | 'deleted';
    file_size?: number;
    number_of_rows?: number;
    number_of_columns?: number;
}

type AnalyticsEvent =
    | AdminEvent
    | AreaEvent
    | CustomDatasetEvent
    | DataExportEvent
    | PolicyEvent;

export const sendEventAnalytics = (analytics: AnalyticsEvent) => {
    recordAnalytics('user event', analytics);
};
