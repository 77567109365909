const LOCAL_STORAGE_KEYS = {
    authToken: 'rr_authToken',
    lastReload: 'ride report chunkloaderror reload attempts',
    orgMasquerade: 'Ride Report Org Masquerade',
    featurePreview: 'Ride Report Use Demo Features',
    dismissedConfidentialityNotice:
        'Ride Report dismissed confidentiality notice',
    explorePrivacyCalloutDismissed:
        'Ride Report page-explore/privacy-callout dismissed',
    routesPrivacyCalloutDismissed:
        'Ride Report page-explore/routes-privacy-callout dismissed'
} as const;

export default LOCAL_STORAGE_KEYS;
