import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { hot } from 'react-hot-loader/root';

import { Switch, Route } from 'react-router';
import App from './page-app';
import ErrorBoundary from '../common/error-boundary';
import { ViewportProvider } from 'utils/use-viewport';
import { IntlProvider } from 'utils/use-intl';
import { usePageViews } from 'utils/use-analytics';
import Eula from './page-eula';
import Privacy from './page-privacy';

/**
 * The top level of the application. Establishes routing and error handling,
 * then immediately switches between /terms (which doesn't require
 * authentication) and everything else, which does.
 */
function InnerRoot() {
    usePageViews();

    return (
        <IntlProvider locale="en">
            <ErrorBoundary size="large">
                <ViewportProvider>
                    <Switch>
                        <Route path="/terms/eula" exact component={Eula} />
                        <Route
                            path="/terms/privacy"
                            exact
                            component={Privacy}
                        />
                        <Route component={App} />
                    </Switch>
                </ViewportProvider>
            </ErrorBoundary>
        </IntlProvider>
    );
}

function Root() {
    return (
        <BrowserRouter>
            <InnerRoot />
        </BrowserRouter>
    );
}

export default hot(Root);
