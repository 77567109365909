import React, { useState } from 'react';
import "/opt/build/repo/src/root/page-login.tsx?resplendence=true";

import { Button, TextField, PageContent } from 'common/layout';
import { AuthInfo } from 'common/authentication';
import config from 'config';
import Header from 'common/header';
import { DateTime } from 'luxon';
import { Icon } from 'ride-ui-kit';

/*
@import 'style';
*/;

const HEADER = "rx-root-page-login-1"/*
    @include headline-3;
*/;

const CONTAINER = "rx-root-page-login-2"/*
    width: 100%;
    max-width: 520rem;
    margin: auto;
*/;

const FORM_CONTAINER = "rx-root-page-login-3"/*
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 32rem;
*/;

const FORM_INPUT_ITEM = "rx-root-page-login-4"/*
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 16rem;
    width: 100%;
    max-width: 520rem;
*/;

const FORM_LABEL = "rx-root-page-login-5"/*
    @include text-label;
    color: $gray-70;
*/;

const REQUIRED = "rx-root-page-login-6"/*
    color: $red-50;
*/;

const BUTTON_CONTAINER = "rx-root-page-login-7"/*
    margin-top: 24rem;
    margin-bottom: 40rem;
*/;

const LINK = "rx-root-page-login-8"/*
    @extend %link;
    font-weight: 500;
    color: $blue-50;
*/;

const ERROR_MESSAGE = "rx-root-page-login-9"/*
    background: $red-00;
    border: 1rem solid $red-50;
    box-sizing: border-box;
    border-radius: 6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 520rem;
    padding: 24rem;
    margin-top: 24rem;
    color: $red-50;

    > span {
        font-family: Work Sans;
        font-style: normal;
        font-weight: 500;
        font-size: 16rem;
        line-height: 150%;
        margin-right: 12rem;
    }
*/;

interface LoginPageProps {
    login: (authInfo: AuthInfo) => void;
}

class LoginError extends Error {
    constructor({ status, statusText }: Response) {
        super(`Could not fetch token. ${status}: ${statusText}`);
    }
}

function LoginPage(props: LoginPageProps) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [formState, setFormState] = useState<
        'initial' | 'loading' | 'invalidCredentials' | 'error'
    >('initial');

    const canSubmit = email.length > 0 && password.length > 0;

    const handleSubmit = async (event: React.FormEvent<HTMLButtonElement>) => {
        event.preventDefault();
        if (!canSubmit) return;

        setFormState('loading');

        const paramData = new URLSearchParams({
            grant_type: 'password',
            client_id: config.clientId,
            username: email,
            password: password
        });

        try {
            const response = await fetch(`${config.authHost}/token`, {
                method: 'POST',
                headers: {
                    'Content-Type':
                        'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: paramData
            });

            const data = await response.json();

            if (!response.ok) {
                // if we receive an invalid grant, it means the login wasn't
                // valid. We'll have the user try again, but there's no need
                // to throw an error
                if (data.error === 'invalid_grant') {
                    setFormState('invalidCredentials');
                    return;
                }

                throw new LoginError(response);
            }

            setFormState('initial');
            props.login({
                token: data.access_token,
                expiresAt: DateTime.utc()
                    .plus({ seconds: data.expires_in })
                    .toMillis()
            });
        } catch (err) {
            setFormState('error');
            throw err;
        }
    };

    return (
        <>
            <Header link="https://ridereport.com/" />
            <PageContent>
                <div className={CONTAINER}>
                    <h1 className={HEADER}>Sign In</h1>
                    <form className={FORM_CONTAINER}>
                        <div className={FORM_INPUT_ITEM}>
                            <label
                                className={FORM_LABEL}
                                htmlFor="sign-in-field-email"
                            >
                                Email<span className={REQUIRED}>*</span>
                            </label>
                            <TextField
                                id="sign-in-field-email"
                                value={email}
                                onChange={setEmail}
                            />
                        </div>
                        <div className={FORM_INPUT_ITEM}>
                            <label
                                className={FORM_LABEL}
                                htmlFor="sign-in-field-password"
                            >
                                Password<span className={REQUIRED}>*</span>
                            </label>
                            <TextField
                                id="sign-in-field-password"
                                value={password}
                                onChange={setPassword}
                                type="password"
                            />
                        </div>

                        <div className={BUTTON_CONTAINER}>
                            <Button
                                loading={formState === 'loading'}
                                disabled={!canSubmit}
                                onClick={handleSubmit}
                                color="blue"
                            >
                                Sign In
                            </Button>
                            {formState === 'invalidCredentials' ||
                            formState === 'error' ? (
                                <div className={ERROR_MESSAGE}>
                                    <span>
                                        {formState === 'invalidCredentials'
                                            ? 'Invalid Sign In, please try again.'
                                            : 'Something went wrong. Please try again or contact us at support@ridereport.com.'}
                                    </span>
                                    <button
                                        onClick={() => setFormState('initial')}
                                    >
                                        <Icon icon="Close" />
                                    </button>
                                </div>
                            ) : null}
                        </div>

                        {/* the authservice still handles the password reset flow */}
                        <a
                            href={`${config.authHost}/accounts/password_reset/`}
                            target="_blank"
                            rel="noreferrer noopener"
                            className={LINK}
                        >
                            Forgot your password?
                        </a>
                        <span>
                            Don't have an account?{' '}
                            <a
                                href="mailto:hello@ridereport.com"
                                className={LINK}
                            >
                                Get in touch.
                            </a>
                        </span>
                    </form>
                </div>
            </PageContent>
        </>
    );
}

export default LoginPage;
